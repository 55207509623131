import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { Helmet } from "react-helmet";

/** @type {React.FC<{ description?: string | null, title?: string | null }>} */
export const Meta = ({ children, description, title }) => {
  const data =
    /** @type {import("../../../graphql/generated").SettingsQuery} */ (
      useStaticQuery(graphql`
        query Settings {
          file(name: { eq: "settings" }) {
            childContentYaml {
              favicon {
                publicURL
              }
              seo {
                title
                description
              }
            }
          }
        }
      `)
    ).file?.childContentYaml;
  const seoTitle = title || data?.seo?.title || "TunStadtMachen";
  const seoDescription = (description || data?.seo?.description)?.replaceAll(
    /\s+/g,
    " "
  );
  return (
    <Helmet defaultTitle={seoTitle}>
      <html lang="de" />
      {data?.favicon?.publicURL && (
        <link rel="icon" href={data?.favicon?.publicURL} type="image/svg+xml" />
      )}
      {seoDescription && <meta content={seoDescription} name="description" />}
      {children}
    </Helmet>
  );
};
