import React, { useContext } from "react";

/** @typedef {React.ComponentProps<typeof DefaultLink>} LinkProps */

/** @type {React.FunctionComponent<{ to: string, children: React.ReactChild } & React.AnchorHTMLAttributes<{}>>} */
const DefaultLink = ({ to, children, ...props }) => (
  <a href={to} children={children} {...props} />
);

/** @type {React.Context<React.ComponentType<LinkProps>>} */
/** @ts-ignore */
const LinkContext = React.createContext(DefaultLink);

/**
 * Use this to provide an alternative component to render links with
 * Defaults to just using the `a` tag.
 *
 * Would likely be used to inject the gatsby `Link` component.
 */
export const LinkProvider = LinkContext.Provider;

/** @type {() => React.ComponentType<LinkProps>} */
export const useLink = () => {
  return useContext(LinkContext) ?? DefaultLink;
};
