import React from "react";

import { ReactComponent as LogoBlockFilled } from "../../images/logo_block_filled.svg";
import { ReactComponent as InstagramLogo } from "../../images/instagram.svg";
import * as styles from "./homepage.module.scss";
import PatternBackground from "../pattern-bg/pattern-bg";
import { FooterLayout } from "../footer/footer";

/** @template T; @typedef {{ [P in keyof T]?: T[P] | null }} NullablePartial */

/** @typedef {NullablePartial<{ src: { publicURL?: string | null }, url: string, alt: string }>} Supporter */

/** @type {React.FC<{ contact?: React.ReactElement | null, intro?: React.ReactElement | null, supporters?: Supporter[] }>} */
export const Homepage = ({ intro, contact, supporters }) => {
  return (
    <div className={styles.splash}>
      <PatternBackground />
      <LogoBlockFilled className={styles.logo} />
      <article className={styles.content}>
        {intro && <section className={styles.intro}>{intro}</section>}
        {contact && (
          <section className={styles.contact}>
            {contact}
            <p>
              <a
                href="https://www.instagram.com/tunstadtmachen/"
                title="tunstadtmachen auf Instagram"
              >
                <InstagramLogo />
              </a>
            </p>
          </section>
        )}
      </article>
      <FooterLayout
        links={[{ path: "https://kalk.space/impressum/", title: "Impressum" }]}
      >
        <section className={styles.supporter}>
          <h2>Unterstützer*innen</h2>
          <div className={styles.supporterLogos}>
            {supporters?.map((sup) => {
              if (!sup?.src?.publicURL) {
                return null;
              }
              const img = (
                <img src={sup.src.publicURL} alt={sup.alt ?? undefined} />
              );
              return sup.url ? <a href={sup?.url}>{img}</a> : img;
            })}
          </div>
        </section>
      </FooterLayout>
    </div>
  );
};
