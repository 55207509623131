import { graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import * as React from "react";
import { Meta } from "../components/meta/meta";
import { Homepage } from "../components/homepage/homepage";

export const query = graphql`
  query Homepage {
    file(relativeDirectory: { eq: "single" }, name: { eq: "homepage" }) {
      childMdx {
        frontmatter {
          contact
          title
          seo {
            description
          }
          supporter {
            src {
              publicURL
            }
            url
            alt
          }
        }
        body
        mdxAST
      }
    }
  }
`;

/**
 * @param {{ children: any[] }} ast
 * @return {boolean}
 */
const isEmptyMdxAst = (ast) => {
  return ast?.children?.length <= 1;
};

/**
 * @template T
 * @param {T | null} t
 * @return {t is T}
 */
const filterNull = (t) => t !== null;

/** @type {React.FC<{ data: import("../../graphql/generated").HomepageQuery }>} */
const IndexPage = ({ data }) => {
  const frontmatter = data.file?.childMdx?.frontmatter;
  const intro = data.file?.childMdx;
  return (
    <>
      <Meta
        description={frontmatter?.seo?.description}
        title={frontmatter?.title}
      />
      <Homepage
        intro={
          isEmptyMdxAst(intro?.mdxAST) ? null : (
            <MDXRenderer>intro?.body</MDXRenderer>
          )
        }
        contact={
          frontmatter?.contact ? (
            <MDXRenderer>{frontmatter?.contact}</MDXRenderer>
          ) : null
        }
        supporters={frontmatter?.supporter?.filter(filterNull)}
      />
    </>
  );
};

export default IndexPage;
