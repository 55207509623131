import clsx from "clsx";
import React, { useEffect, useMemo, useRef, useState } from "react";
import * as styles from "./pattern-bg.module.css";

/** @type {React.FunctionComponent<{ x: number, y: number, scaleFactor: number }>} */
const Triangle = ({ x, y, scaleFactor }) => {
  const renderMe = useMemo(() => Math.random() < 0.7, []);
  const scale = useMemo(
    () => (Math.random() * 7 + 8) * scaleFactor,
    [scaleFactor]
  );
  const rotation = useMemo(() => Math.random() * 360, []);

  if (!renderMe) {
    return null;
  }

  const points = [
    [-25, -25],
    [-25, 25],
    [25, 0],
  ].map(([px, py]) => [px * scale, py * scale]);

  return (
    <polygon
      points={points.map((coords) => coords.join(",")).join(" ")}
      fill="transparent"
      stroke="var(--color-secondary)"
      strokeWidth={10 + 2 * scale}
      x={10}
      y={10}
      transform={`translate(${x} ${y}) rotate(${rotation})`}
    />
  );
};

/** @type {React.FunctionComponent<{ x: number, y: number, scaleFactor: number }>} */
const Circle = ({ x, y, scaleFactor }) => {
  const renderMe = useMemo(() => Math.random() < 0.7, []);
  const scale = useMemo(
    () => (Math.random() * 7 + 6) * scaleFactor,
    [scaleFactor]
  );

  if (!renderMe) {
    return null;
  }

  return (
    <circle
      cx={x}
      cy={y}
      r={scale * 50 + 50}
      fill="transparent"
      stroke="var(--color-secondary)"
      strokeWidth={10 + 2 * scale}
    />
  );
};

const REGION_HEIGHT = 400;
const ELEMENTS = [Triangle, Circle];

/**
 * @type {React.FunctionComponent<{ className?: string }>}
 */
const PatternBackground = ({ className = null }) => {
  /** @type {React.Ref<SVGSVGElement>} */
  const svgRef = useRef(null);
  const [innerWidth, setInnerWidth] = useState(0);
  const [innerHeight, setInnerHeight] = useState(0);

  const elements = useMemo(() => {
    const scaleFactor = innerWidth / 1500;
    const regionCount = Math.ceil(innerHeight / (REGION_HEIGHT * scaleFactor));
    return Array.from({ length: regionCount }, (_item, i) => {
      // left
      const leftX = Math.random() * -20 + 10;
      const leftY = Math.random() * REGION_HEIGHT + i * REGION_HEIGHT;
      const LeftComponent =
        ELEMENTS[Math.floor(Math.random() * ELEMENTS.length)];

      // right
      const rightX = innerWidth + Math.random() * 20 - 10;
      const rightY = Math.random() * REGION_HEIGHT + i * REGION_HEIGHT;
      const RightComponent =
        ELEMENTS[Math.floor(Math.random() * ELEMENTS.length)];

      return (
        <React.Fragment key={i}>
          <LeftComponent x={leftX} y={leftY} scaleFactor={scaleFactor} />,
          <RightComponent x={rightX} y={rightY} scaleFactor={scaleFactor} />,
        </React.Fragment>
      );
    });
  }, [innerWidth, innerHeight]);

  useEffect(() => {
    if (svgRef.current === null) {
      return;
    }
    const svg = svgRef.current;

    const resizeHandler = () => {
      setInnerWidth(svg.clientWidth);
      setInnerHeight(svg.clientHeight);
    };

    const observer = new ResizeObserver(resizeHandler);
    observer.observe(svg);

    resizeHandler();

    return () => observer.unobserve(svg);
  }, [svgRef]);

  return (
    <svg
      viewBox={`0 0 ${innerWidth} ${innerHeight}`}
      className={clsx(styles.bg, className)}
      ref={svgRef}
    >
      {elements}
    </svg>
  );
};

export default PatternBackground;
