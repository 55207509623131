import React from "react";
import clsx from "clsx";
import { useStaticQuery, graphql } from "gatsby";

import * as styles from "./footer.module.scss";
import { useLink } from "../link/link";

/** @type {React.FC<{ links: { path: string, title: string }[], transparent?: boolean }>} */
export const FooterLayout = ({ links, transparent = false, children }) => {
  const Link = useLink();
  return (
    <footer className={clsx(styles.footer, transparent && styles.transparent)}>
      <div className={styles.inner}>
        {children}
        <nav className={styles.nav}>
          {links.map(({ path, title }) => (
            <Link key={path} to={path}>
              {title}
            </Link>
          ))}
        </nav>
      </div>
    </footer>
  );
};

/** @type {React.FC<{}>} */
export const Footer = () => {
  /** @type {import("../../../graphql/generated").FooterNavQuery} */
  const data = useStaticQuery(
    graphql`
      query FooterNav {
        allSitePage {
          nodes {
            path
            pageContext
          }
        }
      }
    `
  );

  const links =
    data?.allSitePage?.nodes
      .filter((page) => page.pageContext.footerLink)
      .map(({ path, pageContext }) => ({ path, title: pageContext.title })) ??
    [];
  return <FooterLayout links={links} />;
};
